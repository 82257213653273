/* Register.css */
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px; /* Set a max-width for the form */
  margin: auto; /* Center the form on the page */
  margin-top: 20px;
}

.register-container h2 {
  margin-bottom: 20px;
}
/* Shared styles for light and dark themes, specific adjustments are made using theme classes */
.input-field, .register-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #0f0897; /* Neon-like border color */
  border-radius: 5px;
  font-size: 1rem;
}

.input-field:focus, .register-button:focus {
  outline: none;
  border-color: #ff6347; /* Highlight focus with a tomato-like color */
}

.register-button:hover {
  background-color: #ff6347; /* Tomato-like color on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Adjustments for light theme */
.light .register-container {
  background-color: #fff; /* Light background */
  color: #000; /* Dark text */
}

.light .input-field, .light .register-button {
  border-color: #000; /* Darker border for better contrast */
}

.message {
  margin-top: 10px;
  font-size: 0.9rem;
}
