/* FAQ.css */
.faq-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  color: #000000;
  font-family: 'Courier New', Courier, monospace;
}

.faq-container {
  color: #e1a7a7;
}
.faq h1 {
  text-align: center;
  color: #61dafb; /* A bright, attention-grabbing color */
}

.faq h2 {
  margin-top: 20px;
  color: #9f1800; /* Tomato color for headings */
}

.faq p {
  margin-top: 10px;
  line-height: 1.6;
  color: #000000; /* Bright text for readability */
}

.faq p {
  color: #f4dede; /* Bright text for readability */
}

@media (max-width: 768px) {
  .faq-container {
    padding: 10px;
  }

  .faq h1 {
    font-size: 1.5rem;
  }

  .faq h2 {
    font-size: 1.2rem;
  }

  .faq p {
    font-size: 1rem;
  }
}
