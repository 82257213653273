/* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px; /* Set a max-width for the form */
  margin: auto; /* Center the form on the page */
  margin-top: 20px;
}

.login-container h2 {
  margin-bottom: 20px;
}
/* Shared styles for light and dark themes, specific adjustments are made using theme classes */
.input-field, .login-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #0f0897; 
  border-radius: 5px;
  font-size: 1rem;
}

.input-field:focus, .login-button:focus {
  outline: none;
  border-color: #0f0897; /* Highlight focus with a tomato-like color */
}

.login-button {
  background-color: #0f0897; /* Neon-like background */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-button:hover {
  background-color: #ff6347; /* Tomato-like color on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.message {
  margin-top: 10px;
  font-size: 0.9rem;
}
