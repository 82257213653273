/* General resets for consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  height: 100%;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
  /* background-color: #f0f0f0; /* Light gray background for daylight readability */
  background-size: 200% 200%;
  /* background-image: #c30101; */
  animation: gradientAnimation 60s ease infinite;
  color: #333; /* Dark text for contrast */
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Adjust to align items from the start */
}

.App.light {
  background-color: #fff;
  color: #000;
}

.App.dark {
  background-color: #1e1e2f; /* Dark navy/indigo for rich contrast */
  color: #f0f0f0; /* Soft white text for readability */
}

/* Updated Navigation Styles */
.App-nav {
  width: 100%;
  background-color: #fff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
}

.dark .App-nav {
  background-color: #2c2c3e;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

.dashboard-icon {
  background-color: #f4f4f8; /* Light grayish background */
  border-radius: 10px;
  padding: 0.8rem;
}

.dark .dashboard-icon {
  color: #fff; 
  background-color: #2900a6; /* Dark mode background */
}

.aifeedback-icon {
  background-color: #f4f4f8; /* Light grayish background */
  border-radius: 10px;
  padding: 0.8rem;
}

.dark .aifeedback-icon {
  color: #fff; 
  background-color: #2900a6; /* Dark mode background */
}
.nav-left, .nav-right {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
}

.nav-center {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  flex: 1;
}

.nav-right {
  justify-content: flex-end;
}

.theme-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Update media query for responsive design */
@media (max-width: 768px) {
  .App-nav {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .nav-left, .nav-center, .nav-right {
    width: 100%;
    justify-content: center;
    gap: 1rem;
  }

  .nav-right {
    justify-content: center;
  }

  .welcome-message {
    margin-left: 0;
  }
}
/* Adjustments for smaller screens and responsiveness */
@media (max-width: 768px) {
  .App-nav a {
    margin: 5px 0; /* Stack links with space in between */
  }

  .Sub-nav {
    flex-wrap: wrap; /* Wrap buttons for smaller screens */
  }
}

.App-nav a {
  color: #3a00c2; /* A modern blue for links */
  text-decoration: none;
  margin: 0 1rem;
  transition: color 0.3s ease;
}

.App-nav a:hover,
.App-nav a:focus {
  color: #ff2a2a; /* Darker blue on hover/focus */
}

.dark .App-nav a {
  color: #ffdcac; /* Warm yellow links for dark theme */
}
.dark .App-nav a:hover,
.dark .App-nav a:focus {
  color: #ffca3a; /* Bright yellow on hover in dark mode */
}


/* Styles for the welcome message */
.welcome-message {
  color: #3a00c2; /* Dark color for readability */
  margin-left: auto; /* This ensures it stays to the right */
  display: flex;
  align-items: center; /* Center message vertically */
}

.dark .welcome-message {
  color: #f0a500; /* Warm yellow for dark theme */
}


/* Header adjustments */
.App-header {
  text-align: center;
  margin-top: 20px;
}

.App-header p {
  color: #3d0091;
  margin-top: 10px;
  font-size: 1.2rem;
  opacity: 0.85;
}

.App-header p2 {
  color: #660505;
  margin-top: 10px;
  font-size: 1.2rem;
  opacity: 0.85;
}

.App-header h1 {
  color: #0f0897; /* Modern blue for a pop of color */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  font-size: 4rem; /* Larger size for emphasis */
}

/* Link and Sub-nav adjustments for day mode */
.Link {
  color: #007bff;
  font-size: 1.2rem; /* Larger size for emphasis */
  text-decoration: none;
  margin: 0 2rem;
  transition: color 0.3s ease;
}

.Link:hover, .Link:focus {
  color: #0056b3;
}

.dark .Link {
  color: #f0a500;
}

.dark .Link:hover, .dark .Link:focus {
  color: #ffca3a;
}

.Sub-nav .Link {
  background-color: #fff4f4;
  color: #0052aa;
  border: 2px solid #0052aa;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.Sub-nav .Link:hover {
  background-color: #0f0897cc;
  color: #ffffff;
}

.dark .Sub-nav .Link {
  background-color: #3a3a52;
  color: #f0a500;
  border: 2px solid #f0a500;
}.dark .Sub-nav .Link:hover {
  background-color: #ffca3a;
  color: #1e1e2f;
}
/* Sub-navigation Styling */
.Sub-nav {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
}

.Sub-nav button {
  background-color: transparent;
  color: #333;
  border: 2px solid #007bff; /* Modern blue border */
  margin: 0 10px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;
  transition:d background-color 0.2s, color 0.2s;
}

.dark .Sub-nav button {
  color: #f0a500;
  border: 2px solid #f0a500;
}

.Sub-nav button:hover {
  background-color: #007bff; /* Blue fill on hover */
  color: #ffffff; /* White text for contrast */
}

/* Enhanced AI Toggle Styles */
.ai-toggle-container {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.ai-toggle-wrapper {
  position: relative;
}

.ai-toggle-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.ai-toggle-label {
  display: block;
  cursor: pointer;
  user-select: none;
}

.toggle-track {
  position: relative;
  width: 80px;
  height: 36px;
  background: linear-gradient(to right, #2c2c3e, #1a1a2e);
  border-radius: 18px;
  transition: all 0.4s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.toggle-indicator {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 30px;
  height: 30px;
  background: #f0a500;
  border-radius: 50%;
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow: 0 2px 8px rgba(240, 165, 0, 0.3);
  z-index: 2;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}

.pulse-circle {
  fill: none;
  stroke: rgba(255, 255, 255, 0.3);
  stroke-width: 1;
  animation: pulse 2s infinite;
}

.core-circle {
  fill: #fff;
  transform-origin: center;
  animation: core-pulse 2s infinite;
}

.toggle-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.text-on, .text-off {
  color: #fff;
  transition: opacity 0.2s ease;
  position: absolute;
  width: 40px;
  text-align: center;
}

.text-on {
  left: 6px;  /* Changed from right: 6px */
  opacity: 0;
}

.text-off {
  right: 6px;  /* Changed from left: 6px */
  opacity: 1;
}
/* Active States */
.ai-toggle-input:checked + .ai-toggle-label .toggle-track {
  background: linear-gradient(to right, #3a00c2, #6b0ac2);
}

.ai-toggle-input:checked + .ai-toggle-label .toggle-indicator {
  transform: translateX(44px);
  background: #f0a500;
}

.ai-toggle-input:checked + .ai-toggle-label .text-on {
  opacity: 1;
}

.ai-toggle-input:checked + .ai-toggle-label .text-off {
  opacity: 0;
}

/* Hover Effects */
.toggle-track:hover {
  box-shadow: 0 4px 15px rgba(240, 165, 0, 0.3);
}

.ai-toggle-input:checked + .ai-toggle-label .toggle-track:hover {
  box-shadow: 0 4px 15px rgba(58, 0, 194, 0.3);
}

/* Animations */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

@keyframes core-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* Dark theme adjustments */
.dark .toggle-track {
  background: linear-gradient(to right, #1a1a2e, #2c2c3e);
}

.dark .ai-toggle-input:checked + .ai-toggle-label .toggle-track {
  background: linear-gradient(to right, #f0a500, #ffca3a);
}

.dark .toggle-indicator {
  background: #3a00c2;
}

.dark .ai-toggle-input:checked + .ai-toggle-label .toggle-indicator {
  background: #3a00c2;
}