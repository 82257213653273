/* Base container styles */
.ai-feedback-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(49, 46, 129, 1) 0%,
    rgba(76, 29, 149, 1) 50%,
    rgba(49, 46, 129, 1) 100%
  );
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.2);
}

/* Header styles */
.ai-header {
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ai-title {
  color: white;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.live-indicator {
  color: rgb(191, 219, 254);
  font-size: 0.75rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Card container styles */
.cards-container {
  padding: 1rem;
  position: relative;
  min-height: 100px;
}

.cards-scroll {
  display: flex;
  gap: 1rem;
  animation: scroll 20s linear infinite;
  will-change: transform;
}

.cards-scroll:hover {
  animation-play-state: paused;
}

/* Individual card styles */
.insight-card {
  flex: 0 0 auto;
  padding: 1rem;
  background: linear-gradient(
    145deg,
    rgba(91, 33, 182, 0.3) 0%,
    rgba(67, 56, 202, 0.3) 100%
  );
  backdrop-filter: blur(8px);
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  min-width: 280px;
  max-width: 400px;
}

.insight-card:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.insight-text {
  color: white;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

/* Status indicator styles */
.status-indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.status-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgb(74, 222, 128);
  position: relative;
}

.status-dot::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgb(74, 222, 128);
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.status-text {
  color: rgb(74, 222, 128);
  font-size: 0.75rem;
}

/* Loading animation styles */
.loading-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.loading-circle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: rgba(96, 165, 250, 0.4);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.loading-lines {
  flex: 1;
}

.loading-line {
  height: 0.75rem;
  background-color: rgba(96, 165, 250, 0.4);
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.loading-line:last-child {
  width: 60%;
}

/* Gradient overlay effects */
.gradient-overlay-left,
.gradient-overlay-right {
  position: absolute;
  top: 0;
  width: 4rem;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.gradient-overlay-left {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(49, 46, 129, 1) 0%,
    rgba(49, 46, 129, 0) 100%
  );
}

.gradient-overlay-right {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(49, 46, 129, 1) 0%,
    rgba(49, 46, 129, 0) 100%
  );
}

/* Animations */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .insight-card {
    min-width: 240px;
  }
  
  .ai-title {
    font-size: 0.75rem;
  }
  
  .insight-text {
    font-size: 0.75rem;
  }
}
.ai-insight-container {
  background: linear-gradient(135deg, #1a1c2c, #2a2d4a);
  border-radius: 12px;
  padding: 20px;
  color: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: 0 auto;
}

.insight-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.level-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: bold;
  color: #1a1c2c;
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.3);
  animation: pulse 2s infinite;
}

.stats-container {
  display: flex;
  gap: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.9em;
}

.progress-section {
  background: rgba(255, 255, 255, 0.05);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.progress-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 0.9em;
  color: #b8b9cf;
}

.progress-bar {
  height: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 12px;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
  border-radius: 4px;
  transition: width 0.5s ease-out;
}

.star-progress {
  display: flex;
  gap: 4px;
  justify-content: center;
}

.star-icon {
  transition: all 0.3s ease;
}

.star-active {
  color: #ffd700;
  filter: drop-shadow(0 0 4px rgba(255, 215, 0, 0.5));
}

.star-inactive {
  color: rgba(255, 255, 255, 0.2);
}

.recommendation-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
}

.recommendation-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  color: #4CAF50;
  font-weight: bold;
}

.card-icon {
  animation: bounce 1s infinite;
}

.recommendation-text {
  color: #e0e0e0;
  line-height: 1.6;
  margin-bottom: 16px;
}

.action-button {
  background: linear-gradient(135deg, #4CAF50, #8BC34A);
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.action-button:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.3);
}

.loading-pulse {
  text-align: center;
  padding: 20px;
}

.pulse-bars {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 12px;
}

.pulse-bars div {
  width: 4px;
  height: 20px;
  background-color: #4CAF50;
  border-radius: 2px;
  animation: pulse-bar 1s infinite;
}

.pulse-bars div:nth-child(2) {
  animation-delay: 0.2s;
}

.pulse-bars div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
}

@keyframes pulse-bar {
  0%, 100% { transform: scaleY(1); }
  50% { transform: scaleY(1.5); }
}

@media (max-width: 480px) {
  .insight-header {
    flex-direction: column;
    gap: 12px;
  }
  
  .stats-container {
    width: 100%;
    justify-content: space-between;
  }
}
.action-button {
  background: linear-gradient(135deg, #4CAF50, #8BC34A);
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.action-button:hover:not(.button-disabled) {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.3);
}

.button-disabled {
  background: linear-gradient(135deg, #808080, #a0a0a0);
  cursor: not-allowed;
  opacity: 0.7;
}

.level-up-animation {
  animation: levelUp 1s ease-out;
}

@keyframes levelUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    filter: brightness(1.5);
  }
  100% {
    transform: scale(1);
  }
}
