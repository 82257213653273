/* PrivacyPolicy.css */
.PrivacyPolicy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

.PrivacyPolicy h1, .PrivacyPolicy h2 {
  color: #333;
  margin-bottom: 20px;
}

.PrivacyPolicy p {
  color: #000000;
  line-height: 1.6;
  margin-bottom: 20px;
}
