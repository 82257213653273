.App-footer {
  text-align: center;
  padding: 20px;
  margin-top: 30px;
  color: #333; /* Dark text for readability */
}

.footer-links a, .social-links a {
  margin: 0 10px;
  color: #007bff; /* Blue links for visibility */
  text-decoration: none;
}

.footer-links a:hover, .social-links a:hover {
  text-decoration: underline;
}

.social-links {
  margin-top: 10px;
}
