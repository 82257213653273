/* About.css */
.about-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  color: #0f0897;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 8px; /* Soft rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.dark .about-container {
  color: #aeabe3;
}

.container h1 {
  text-align: center;
  color: #0f0897; /* Bright blue for headings */
  margin-bottom: 20px; /* Space below the heading */
}

.about-container p {
  text-align: justify; /* Justify text for better readability */
  margin-bottom: 10px; /* Space between paragraphs */
  line-height: 1.5; /* Increased line height for readability */
}

about-container .signature p {
  text-align: right;
}

@media (max-width: 768px) {
  .about-container {
    padding: 15px;
  }

  .about-container h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  .about-container p {
    font-size: 0.9rem; /* Adjust font size for readability on smaller screens */
  }
}
