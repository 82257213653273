.TermsOfUse {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  color: #000000; 
}

.TermsOfUse h1, .TermsOfUse h2 {
  color: #333;
  margin-bottom: 20px;
}

.TermsOfUse p {
  color: #141414;
  line-height: 1.6;
  margin-bottom: 20px;
}

.TermsOfUse a {
  color: #007bff;
  text-decoration: none;
}

.TermsOfUse a:hover {
  text-decoration: underline; 
}

