/* Adjusting Pomodoro styles to match the modern day theme */
.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Courier New', Courier, monospace;
  padding: 20px;
  border-radius: 0;
  width: 100%;
  height: auto;
  color: #333; /* Text color adjusted for the light theme */
}

/* Dark theme styles */
.dark .timer-container {
  background-color: #1e1e2f; /* Deep indigo/navy background for dark theme */
  color: #f0f0f0; /* Soft white text for dark theme */
}

.pomodoro-modes button:hover, .break-modes button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.15); /* Slightly enhanced shadow on hover */
}

/* Timer display adjustments for light/dark theme */
.timer-display {
  font-size: 8rem;
  color: #09055c; /* Dark text on light background */
  letter-spacing: 4px;
  margin-top: 20px;
}

/* Timer display adjustments for dark theme */
.dark .timer-display {
  color: #f0a500; /* Warm yellow for dark background */
}

.timer-controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.timer-controls button:hover {
  transform: scale(1.1); /* Feedback effect */
}

.timer-controls button:active {
  border-color: #09055c; /* Slightly darker blue for active state */
  transform: scale(0.9); /* Press effect */
}

/* Timer controls button styles for dark theme */
.dark .timer-controls button:active {
  border-color: #f0a500; /* Yellow border for active state in dark theme */
}

.description-textarea {
  margin-top: 50px;
  width: 200%;
  height: 140px;
  background-color: #f6f4fd; /* Dark background for textarea */
  color: #0056b3; /* Neon-like text color */
  border: 3px solid #0056b3; /* Neon-like border */
  border-radius: 20px;
  padding: 10px;
  font-size: 1.2rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}
/* Dark theme textarea */
.dark .description-textarea {
  background-color: #333; /* Darker background for textarea */
  color: #f0a500; /* Warm yellow text color */
  border: 3px solid #f0a500; /* Yellow neon-like border */
}

/* Adjusting button styles to fit the modern day theme */
.timer-controls button {
  color: #09055c; /* Modern blue for icons and text */
  background-color: transparent;
  border: none;
  width: 80px;
  height: 80px;
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

/* Ensuring SVG Icon visibility */
.timer-controls button svg {
  fill: #09055c;
  width: 80px; /* Let the size be determined by the content */
  height: 80px; /* Let the size be determined by the content */
}
/* Dark theme buttons */
.dark .timer-controls button {
  color: #f0a500; /* Soft white text in dark theme */
}
/* Dark theme SVG icon */
.dark .timer-controls button svg {
  fill: #f0a500; /* Warm yellow for icons in dark theme */
}
/* Icon Size Adjustments */
.icon {
  fill: #61dafb; /* Icon color */
  width: 24px; /* Smaller icon width */
  height: 24px; /* Smaller icon height to maintain aspect ratio */
}

/* Revert "Feed AI" Button to Original Styling */
.ai-send-button {
  margin-top: 30px;
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #9d50bb; /* Purple gradient */
  color: #fff;
  border: none;
  border-radius: 50px; /* Rounded edges for a pill-like shape */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  font-family: 'Courier New', Courier, monospace; /* Retro look */
  text-shadow: 1px 1px 2px #000; /* Soft text shadow for depth */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for a floating effect */
}
.dark .ai-send-button {
  background-color: #272777; /* Deep navy background */
  color: #f0a500; /* Warm yellow text */
  text-shadow: none;
}
.ai-send-button:hover {
  transform: translateY(-2px); /* Slight lift when hovered */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a popping effect */
}

.ai-send-button:active {
  transform: translateY(1px); /* Push effect when clicked */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Subdued shadow to simulate pressing down */
}

.error-message {
  margin-top: 10px;
  color: rgb(79, 0, 0);
}
.dark .error-message {
  color: #ff4d4d; /* Bright red for errors in dark theme */
}

.dark .App-nav {
  background-color: #2c2c54; /* Dark purple/navy for nav background */
}

.dark .Link {
  color: #f0a500; /* Warm yellow for links in dark theme */
}