/* Setup.css */
.setup-container {
  padding: 20px;
  max-width: 900px;
  margin: 20px auto;
  color: #000000;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 8px; /* Soft rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.setup-container {
  color: #d1700f;
}

.setup-container h1 {
  text-align: center;
  color: #0f0897; /* Bright blue for headings */
  margin-bottom: 30px;
}
.dark .setup-container h1 {
  color: #975908; /* Bright blue for headings */
}


.setup-field {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
  align-items: center;
  margin-bottom: 20px; /* Space between fields */
}

.setup-field label {
  margin-right: 160px;
}

.setup-field input {
  width: 20%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #0f0897; /* Bright blue border */
  background-color: transparent;
  color: #000000;
}

.dark .setup-field input {
  border: 1px solid #974d08; /* Bright blue border */
  color: #c8780f;
}

.setup-submit {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #0f0897; /* Bright blue for submit button */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.dark .setup-submit {
  background-color: #472004; /* Bright blue for submit button */
  color: #ffffff;
}


.setup-submit:hover {
  background-color: #ff6347; /* Tomato color on hover */
}
